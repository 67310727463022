import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/imprint.scss';

import marked from "./markdown";


class Imprint extends Component {

    render() {

        if (!!this.props.data) {
            let imprintHeadline = this.props.data.filter(t => t['type'] === 'headline_imprint')[0]['content'];
            let imprint = this.props.data.filter(t => t['type'] === 'imprint')[0]['content'];
            let privacyHeadline = this.props.data.filter(t => t['type'] === 'headline_privacy')[0]['content'];
            let privacy = this.props.data.filter(t => t['type'] === 'privacy')[0]['content'];

            return (
                <div id={'impressum'} className={'imprint'}>

                    <div className="horizontalShadow" />

                    <div className={'sections'}>
                        <div className={'icon-close close'} onClick={() => {this.props.hide()}} title={'Schließen'} />

                        <section>
                            <h3>{imprintHeadline}</h3>

                            <div dangerouslySetInnerHTML={{__html: marked(imprint)}} />
                        </section>

                        <section>
                            <h3>{privacyHeadline}</h3>

                            <div dangerouslySetInnerHTML={{__html: marked(privacy)}} />
                        </section>
                    </div>
                </div>
            );
        } else {
            return <></>
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show) {

            let imprint = document.querySelector('#impressum');

            if (this.props.show) {
                imprint.style.maxHeight = '9999px';
            } else {
                imprint.style.maxHeight = '0';
            }

            setTimeout(() => {
                let container = imprint;

                if (!!container) {
                    let position = container.offsetTop;

                    let topBar = document.querySelector('header');
                    let topBarHeight = topBar.getBoundingClientRect();

                    window.scroll({
                        top: position - topBarHeight.height,
                        behavior: 'smooth'
                    });
                }
            }, 200);

        }

        if (prevProps.resetAll !== this.props.resetAll && !!this.props.resetAll) {
            this.props.hide()
        }
    }
}

export default withRouter(Imprint);

import React, {Component} from 'react';

import '../assets/scss/listbox.scss'


class Faq extends Component {
    constructor() {
        super();

        this.state = {
            headline: '',
            list: {}
        }
    }

    render() {
        return (
            <div className={'listBox'} id={this.props.id}>

                <div className="horizontalShadow" />

                <div className={'headlines'}>
                    <h2>
                        {this.state.headline}
                    </h2>
                </div>

                <ul className={'entries'}>
                    {Object.keys(this.state.list).length && Object.keys(this.state.list).map(l => {
                        let entry = this.state.list[l];

                        if (!!entry) {
                            return <li className={'entry'} key={'entry_' + l}>
                                <h3>{entry.q}</h3>
                                <p>{entry.a}</p>
                            </li>;
                        } else {
                            return <></>;
                        }

                    })}
                </ul>

            </div>
        );
    }

    componentDidMount() {
        if (!!this.props.data) {
            let list = {};

            this.props.data.filter(e => e['type'] !== 'headline').forEach(l => {
                if (!list[l['index']]) {
                    list[l['index']] = {
                        q: '',
                        a: ''
                    };
                }

                list[l['index']][l['type']] = l['content'];
            });

            let headline = this.props.data.filter(e => e['type'] === 'headline')[0];

            this.setState({
                headline: headline['content'],
                list: list
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.data !== prevProps.data) {
            let list = {};

            this.props.data.filter(e => e['type'] !== 'headline').forEach(l => {
                if (!list[l['index']]) {
                    list[l['index']] = {
                        q: '',
                        a: ''
                    };
                }

                list[l['index']][l['type']] = l['content'];
            });

            let headline = this.props.data.filter(e => e['type'] === 'headline')[0];

            this.setState({
                headline: headline['content'],
                list: list
            });
        }
    }
}

export default Faq;

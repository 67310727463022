import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import axios from 'axios';

import Header from './components/header.class';

import Start from './components/start.class';
import Slider from './components/slider.class';
import Listbox from './components/listbox.class';
import Contact from './components/contact.class';
import Imprint from './components/imprint.class';

import Loading from "./loading";

const DynamicComponent = (props) => {
    let components =  {
        'Start': <Start {...props} />,
        'Textbox_mit_Bild': <Slider {...props} />,
        'Listbox': <Listbox {...props} />,
        'Contact': <Contact {...props} />
    };

    if (!!components[props.view]) {
        return components[props.view];
    } else {
        return null;
    }

};


class AppRouter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.openTab = this.openTab.bind(this);
        this.goTop = this.goTop.bind(this);
        this.showImprint = this.showImprint.bind(this);

        let availableLanguages = ["de", "en"];

        this.language =
            [
                ...(window.navigator.languages || []),
                window.navigator.language,
                window.navigator.browserLanguage,
                window.navigator.userLanguage,
                window.navigator.systemLanguage
            ]
            .filter(Boolean)
            .map(language => language.substr(0, 2))
            .find(language => availableLanguages.includes(language)) || "en";
    }

    clickAndScroll(to, bottom = null) {
        let container = document.querySelector(to);

        if (!!container) {
            let position = container.offsetTop;

            let topBar = document.querySelector('header');
            let topBarHeight = topBar.getBoundingClientRect();

            let btm = bottom ? (position + container.getBoundingClientRect().height) - window.innerHeight : position - topBarHeight.height;

            if (!!to) {
                window.scroll({
                    top: btm,
                    behavior: 'smooth'
                });
            }
        }

    }

    goTop(to) {

        this.clickAndScroll(to);

    }

    openTab(href, tab) {
        this.setState({
            [href]: tab
        });
    }

    showImprint() {
        if (!this.state.imprint) {
            this.setState({
                imprint: true
            });
        } else {
            this.clickAndScroll('#impressum');
        }
    }

    hideImprint() {
        this.setState({
            imprint: false
        });
    }

    render() {

        let className = '';
        let json;

        let path = new URLSearchParams(this.props.location.search);
        let _lang = path.get('lang');
        let lang = this.language;
        if (_lang === 'de' || _lang === 'en') {
            lang = _lang;
        }

        let imprintdata = (!!this.state.current) ? this.state.current['/'].get('datenschutz')['data'] : null;
        let imprintPrivacy = !!imprintdata ? imprintdata.filter(t => t['type'] === 'headline_imprintPrivacy')[0]['content'] : 'Impressum & Datenschutz';

        if (!!this.state.current && !!this.state.current['/']) {
            json = this.state.current['/'];

            if (this.state.loading) {
                return <Loading />
            } else {
                let content = [];
                if(json.size > 0) {
                    json.forEach(elm => {
                        if (elm['view']) {
                            content.push(
                                <DynamicComponent
                                    id={elm['id']}
                                    view={elm['view']}
                                    data={elm['data']}
                                    key={elm['id']}
                                    setTab={this.setTab}
                                    // tab={this.state.tab['#' + elm['id']]}
                                    clickAndScroll={this.clickAndScroll}
                                />
                            );
                        }
                    })
                }

                return (
                    <Route className={'pageContainer ' + className}>

                        <Header data={this.state.menu[lang]} goto={this.goTop} setTab={this.setTab} showImprint={this.showImprint} />

                        <div id={'content'}>
                            {content}

                            <footer id={'footer'}>

                                <div className={'horizontalLine'}/>

                                <div className={'footerInner'}>
                                    <div className={'name'}>
                                        mvolution GmbH {new Date().getFullYear()}
                                    </div>

                                    <div className={'links'}>
                                        <span onClick={() => this.showImprint()}>{imprintPrivacy}</span>
                                    </div>
                                </div>

                            </footer>

                            {!!this.state.current['/'].get('datenschutz') &&
                                <Imprint hide={() => this.hideImprint()} show={this.state.imprint} data={this.state.current['/'].get('datenschutz')['data']} />
                            }
                        </div>
                    </Route>
                );
            }
        } else {
            return <></>;
        }
    }

    componentDidMount() {

        let path = new URLSearchParams(this.props.location.search);
        let _lang = path.get('lang');
        let lang = this.language;
        if (_lang === 'de' || _lang === 'en') {
            lang = _lang;
        }

        axios.get((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_URI'] : window.location.origin) + '/json.php', {
            'headers': {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(result => {
            let data = result.data;
            let pages = {};

            data['Struktur'].forEach(s => {

                let keys = Object.keys(data[s['sheet']]);

                if (!!keys && keys.length) {
                    for (let i = 0; i < keys.length; i++) {
                        if (!pages[keys[i]]) {
                            pages[keys[i]] = {};
                        }

                        if (!pages[keys[i]][s['page']] && !!s['page']) {
                            pages[keys[i]][s['page']] = new Map();
                        }

                        pages[keys[i]][s['page']] = pages[keys[i]][s['page']].set(s['id'], {
                            id: s['id'],
                            view: s['view'],
                            data: data[s['sheet']][keys[i]]
                        });
                    }
                }
            });

            this.setState({
                loading: false,
                pages: pages,
                current: pages[lang],
                menu: data['Menue'],
            });

        });

        setTimeout(() => {
            if (window.location.hash) {
                const id = window.location.hash.replace("#", "");
                const element = document.getElementById(id);

                if (!!element) {
                    this.clickAndScroll('#' + id);
                } else {
                    if (id === 'datenschutz') {
                        this.showImprint();
                    }
                }
            }
        }, 1500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search !== this.props.location.search) {

            let _language = new URLSearchParams(this.props.location.search);
            let _lang = _language.get('lang');

            let language;
            if (!!_lang && ['de', 'en'].includes(_lang)) {
                language = _lang;
            } else {
                language = this.language;
            }

            this.setState({
                current: this.state.pages[language]
            });
        }
    }

}

export default withRouter(AppRouter);

import React, {Component} from 'react';

import '../assets/scss/contact.scss';


class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sent: false,
            error: false,
            form: {
                mail: '',
                name: '',
                reason: '',
                text: '',
                lolField: '',
                terms: false
            }
        };

        this.change = this.change.bind(this);
        this.send = this.send.bind(this);
    }

    change(e) {
        let form = Object.assign({}, this.state.form, {[e.target.name]: e.target.value});

        this.setState({
            form: form
        });
    }

    changeTerms() {
        let form = Object.assign({}, this.state.form, {'terms': !this.state.form.terms});

        this.setState({
            form: form
        });
    }

    send(e) {
        e.preventDefault();

        let state = this.state.form;

        this.setState({
            loading:true,
            error: false
        });

        fetch('/contact.php',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: Object.keys(state).map(function(key) {
                return key + '=' + state[key];
            }).join('&')
        })
        .then(response => {
            if (response.ok) {
                this.setState({
                    loading:false,
                    sent: true,
                    error: false
                });
            } else {
                this.setState({
                    loading:false,
                    sent: false,
                    error: true
                });
            }
        })
        .catch(error => {
            this.setState({
                loading:false,
                sent: false,
                error: true
            });
        });
    }

    render() {

        let headline = this.props.data.filter(h => h['type'] === 'headline')[0]['content'];
        let name = this.props.data.filter(h => h['type'] === 'name')[0]['content'];
        let mail = this.props.data.filter(h => h['type'] === 'mail')[0]['content'];
        let reason = this.props.data.filter(h => h['type'] === 'reason')[0]['content'];
        let text = this.props.data.filter(h => h['type'] === 'text')[0]['content'];
        let button = this.props.data.filter(h => h['type'] === 'button')[0]['content'];
        let error = this.props.data.filter(h => h['type'] === 'error')[0]['content'];
        let done = this.props.data.filter(h => h['type'] === 'done')[0]['content'];

        return (
            <div id={this.props.id} className={'contact'}>

                <div className="horizontalShadow" />

                <div className={'headlines'}>
                    <h2>
                        {headline}
                    </h2>
                </div>

                <div className={'contactBackgroundBox'}>
                    <div className={'contactBox'}>

                        <form onSubmit={(e) => this.send(e)} method={'post'} className={'box contactForm'}>

                            <div className={'left'}>
                                <input type={'text'} defaultValue={this.state.form.name} name={'name'} placeholder={name} required={true} onChange={(e) => this.change(e)} />

                                <input type={'mail'} defaultValue={this.state.form.mail} name={'mail'} placeholder={mail} required={true} onChange={(e) => this.change(e)} />

                                <input type={'text'} defaultValue={this.state.form.reason} name={'reason'} placeholder={reason} required={true} onChange={(e) => this.change(e)} />
                            </div>

                            <div className={'right'}>

                                <textarea name={'text'} defaultValue={this.state.form.text} placeholder={text} required={true} onChange={(e) => this.change(e)} />

                                <input type="text" defaultValue={this.state.form.lolField} name="lolField" className="secCheck" title="Feld" onChange={(e) => this.change(e)} />

                                <div className={'error ' + (this.state.error ? 'visible' : '')}>
                                    {error}
                                </div>

                                {!this.state.sent ?
                                    <button type={'submit'}>
                                        {this.state.loading ?
                                            <div className={'loader'}/> :
                                            button
                                        }
                                    </button>
                                    :
                                    <div className={'ok'}>
                                        {done}
                                    </div>
                                }
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        );
    }
}

export default Contact;

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import LazyImage from './lazyImage.class';

import '../assets/scss/start.scss';

import {shuffle} from './sliderFunctions';
import logo from "../assets/img/icon_80.png";


class Start extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: 0,
            prev: null,
            animation:'next',
            delay:10000,

            slides: [],

            load: true
        };

        this.timeout = null;

        this.goTo = this.goTo.bind(this);

        this.parallax();

        this.animate();

        this.slides = this.getData();
        this.slides = shuffle(this.slides);

        this.download = this.props.data.filter(e => e['type'] === 'download');
    }

    getData() {
        let start = [];

        if (!!this.props.data && this.props.data.length) {
            let images = this.props.data.filter(img => {
                return img['type'] === 'image';
            });
            shuffle(images);

            let headline = '';
            let subheadline = '';

            this.props.data.map((s, k) => {
                let img = null;

                if (!!s['Bild']) {
                    img = s['Bild'];
                } else {
                    if (!!images[k]) {
                        img = images[k]['content'];
                    }
                }

                headline = s['type'] === 'headline' ? s['content'] : '';
                subheadline = s['type'] === 'subheadline' ? s['content'] : '';

                if (!!img) {
                    return start.push({
                        image: img,
                        headline: headline,
                        subheadline: subheadline
                    });
                }  else {
                    return null;
                }
            });
        }

        return start;
    }

    parallax() {
        let _self = this;

        window.addEventListener('scroll', () => {
            let topDistance = window.scrollY;
            let layers = document.querySelectorAll("[data-parallax='true']");

            layers.forEach(layer => {
                // let boxTop = layer.getBoundingClientRect().top;

                let depth = layer.getAttribute('data-depth');
                let movement = -(topDistance * depth);
                let translate3d = 'translate3d(0, ' + movement + 'px, 0)';

                layer.style['-webkit-transform'] = translate3d;
                layer.style['-moz-transform'] = translate3d;
                layer.style['-ms-transform'] = translate3d;
                layer.style['-o-transform'] = translate3d;
                layer.style.transform = translate3d;

                return true;
            });

            let textBoxes = document.querySelector('.startBox .startBoxSlider .textBoxes');
            let mainMenuBox = document.querySelector('header .centerBox');
            let mainMenuFlex = document.querySelector('header .flex');
            let sideMenuBox = document.querySelector('header .menu');

            let dist = topDistance / (_self.boxHeight/3);

            if (!!textBoxes) {
                if (1 - dist >= 0) {
                    textBoxes.style.opacity = 1 - dist;
                }
            }

            if (!!mainMenuBox) {
                if (1 - dist) {
                    mainMenuBox.style.opacity = 1 - dist;
                    mainMenuFlex.style.opacity = 1 - dist;
                }
            }

            if (!!sideMenuBox) {
                if (dist <= 1.5) {
                    sideMenuBox.style.opacity = dist;
                }
            }

        });
    }

    animate() {

        if (!!this.slides && this.slides.length > 1) {
            if (!!this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.goTo();
                this.animate();
            }, this.state.delay);
        }

    }

    goTo() {
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }

        let len = this.props.data.length;
        let current, prev;


        prev = this.state.current;

        if (this.state.current === len-1) {
            current = 0;
        } else {
            current = this.state.current + 1;
        }


        this.setState({
            current: current,
            prev: prev
        });

        setTimeout(() => {
            this.setState({
                prev: null
            });
        },400);

    }

    goToIndex(i) {
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }

        let prev = this.state.current,
            animation = 'next';

        if (i<prev) {
            animation = 'prev';

            this.setState({
                animation: 'stop prev'
            });
        }

        setTimeout(() => {
            this.setState({
                current: i,
                prev: prev,
                animation: animation
            });

            setTimeout(() => {
                this.setState({
                    prev: null,
                    animation: 'next'
                });

                this.animate();
            },400);
        },100);
    }

    render() {
        return (
            <>
                <div id={this.props.id} className={'startBox'}>

                    <div className={'startBoxSlider'}>

                        <div className={'slides'}>

                            <div className={'slidesBox ' + this.state.animation}>
                                {!!this.slides &&

                                this.slides.map((data, key) => {

                                        let cssClass = '';

                                        if (key === this.state.current) {
                                            cssClass = 'currentSlide'
                                        } else if (key === this.state.prev) {
                                            cssClass = 'prevSlide';
                                        }

                                        return (
                                            <div className={'slide ' + cssClass} key={'start_' + key}>
                                                <div className={'imgBox'} data-depth={'0.40'} data-parallax={'true'}>
                                                    {this.state.load &&
                                                        <LazyImage dkey={'start_' + key} className={'img'} alt={'start_img_' + key} src={'image.php?file=' + process.env.PUBLIC_URL + data['image'] + '&height=800&width=1920'} forceLoad={this.state.load} />
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })

                                }
                            </div>

                        </div>

                        <div className={'textBoxes'}>
                            {!!this.slides &&
                                this.slides.map((data, key) => {
                                    let cssClass = '';

                                    if (key === this.state.current) {
                                        cssClass = 'current'
                                    }

                                    return (
                                        <div className={'textBox ' + cssClass} key={'start_text_' + key}>

                                            <div className={'headlines'}>
                                                <div className={'logo'}>
                                                    <img src={logo} onClick={e => this.clickAndScroll(e, '#start')} title={'Startseite'} alt={'#tagetes'} />
                                                    <h2>#tagetes</h2>
                                                </div>
                                                <h1 className="headline ">
                                                    {data['headline']}
                                                </h1>

                                                {(!!this.download && this.download.length > 0) &&
                                                    <div className={'download'}>
                                                        {
                                                            this.download.map((d, k) => {
                                                                return (
                                                                    <a key={'download_' + k} href={d['content']} target={'_blank'}>
                                                                        <img src={'assets/' + d['image']} alt={'Download'} />
                                                                    </a>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className={'bubbleBox'}>
                            {(!!this.slides && this.slides.length > 1) &&
                                this.slides.map((data, key) => {
                                    return <div key={'bubble_' + key} className={'bubble ' + (this.state.current === key ? 'current' : '')} onClick={() => this.goToIndex(key)} />;
                                })
                            }
                        </div>

                    </div>

                    <div className="horizontalShadow" />

                </div>
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextProps.data !== this.props.data || nextState !== this.state);
    }

    componentDidMount() {
        this.box = document.querySelector('.startBoxSlider');
        this.boxPositions = this.box.getBoundingClientRect();
        this.boxHeight = this.boxPositions.height;
        this.textBox = document.querySelector('.startBoxSlider .textBox');

        this.download = this.props.data.filter(e => e['type'] === 'download');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data && !!this.props.data) {

            if (!!this.timeout) {
                clearTimeout(this.timeout);
            }

            let current = document.querySelector('.startBox .currentSlide');
            let p = current.querySelector('div');
            let z = p.querySelector('div');

            if (!!current) {
                z.style.opacity = 0;
            }

            this.setState({
                current: 0,
                prev: null,
                animation:'stop',
                load: false
            });

            setTimeout(() => {
                this.setState({
                    animation:'next',
                    load: true
                });

                this.animate();

                z.removeAttribute('style');
            }, 50);

            this.box = document.querySelector('.startBoxSlider');
            this.boxPositions = this.box.getBoundingClientRect();
            this.boxHeight = this.boxPositions.height;
            this.textBox = document.querySelector('.startBoxSlider .textBox');

            this.slides = this.getData();

            this.slides = shuffle(this.slides);

            this.download = this.props.data.filter(e => e['type'] === 'download');
        }
    }

}

export default withRouter(Start);

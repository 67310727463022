import React, {Component} from 'react';


class Navigation extends Component {
    render() {
        return (
            <nav>
                {this.props.data.map(m => {
                    return (
                        <li key={'top_' + m['id'] + '_' + m['link']}>
                            <a href={m['link'] + (!!m['id'] ? '#' + m['id'] : '')} onClick={e => this.props.clickAndScroll(e, '#' + m['id'], m['tab'])}>
                                {m['name']}
                            </a>
                            {(m['tabs'].length > 0) &&
                            <ul>
                                {
                                    m['tabs'].map(s => {
                                        return (
                                            <li key={'sub_' + s['id'] + '_' + s['tab']}>
                                                <a href={m['link']} onClick={e => this.props.clickAndScroll(e, '#' + m['id'], s['tab'])}>
                                                    {s['name']}
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            }
                        </li>
                    );
                })}
            </nav>
        );
    }
}

export default Navigation;

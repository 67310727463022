import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';

import {CSSTransition} from "react-transition-group";

import '../assets/scss/header.scss';
import Navigation from "./navigation.class";

import logo from '../assets/img/icon_80.png';


class TopBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.clickAndScroll = this.clickAndScroll.bind(this);
        this.openMenu = this.openMenu.bind(this);
    }

    clickAndScroll(e, id, tab = null) {
        e.preventDefault();

        let elm = document.querySelector(id);

        let header = document.querySelector('header');
        let headerData = header.getBoundingClientRect();

        if (!!elm) {
            let position = elm.offsetTop - headerData.height;

            window.location.hash = id;
            window.scroll({
                top: position,
                behavior: 'smooth'
            });
        } else if (id === '#datenschutz') {
            window.location.hash = id;
            this.props.showImprint();
        }

        this.setState({
            open: false
        });

        if (!!tab) {
            this.props.setTab(id, tab);
        }
    }

    openMenu() {
        let state = !this.state.open;

        this.setState({open: state});
    }

    render() {

        let menu = [];

        if (!!this.props.data) {
            this.props.data.forEach((d) => {
                if (!!d['tab']) {
                    let m = menu.filter(e => e['id'] === d['link']);

                    if (m.length) {
                        m[0]['tabs'].push({
                            id: d['id'],
                            link: d['link'],
                            name: d['name'],
                            tab: d['tab']
                        });
                    }
                } else {
                    menu.push({
                        id: d['id'],
                        link: d['link'],
                        name: d['name'],
                        tabs: []
                    });
                }
            });
        }

        return (
            <>
                <header>

                    <div className={'header'}>
                        <div className={'logo'} onClick={e => this.clickAndScroll(e, '#start')}>
                            <img src={logo} title={'Startseite'} alt={'#tagetes'} />
                            <h1>#tagetes</h1>
                        </div>

                        <div className={'centerBox'}>
                            <Navigation data={menu} clickAndScroll={this.clickAndScroll} />
                        </div>

                        <div className={'flex'}>
                            <Link to={'/?lang=de'}>DE</Link>
                            |
                            <Link to={'/?lang=en'}>EN</Link>
                        </div>

                        <div className={'menu'} onClick={() => this.openMenu()}>
                            <div className={'menuBox ' + (this.state.open ? 'opened' : '')}>
                                <span className="line_1" />
                                <span className="line_2" />
                                <span className="line_3" />
                            </div>
                        </div>
                    </div>

                </header>

                <CSSTransition in={this.state.open === true} timeout={200} classNames={'overlay'}>
                    <div id={'overlay'} className={(this.state.open ? 'opened' : '')} onClick={() => this.setState({open: false})} />
                </CSSTransition>

                <div id={'mainMenu'} className={(this.state.open ? 'opened' : '')}>

                    <div className={'logoBox'} onClick={e => this.clickAndScroll(e, '#start')}>
                        <img src={logo} title={'Startseite'} alt={'#tagetes'} />
                        <h1>#tagetes</h1>
                    </div>

                    <Navigation data={menu} clickAndScroll={this.clickAndScroll} />

                </div>
            </>
        );
    }
}

export default withRouter(TopBar);

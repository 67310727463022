import React, {Component} from 'react';

import '../assets/scss/slider.scss';
import marked from "./markdown";

import ImageSlider from "./imageSlider.class";
import LazyImage from "./lazyImage.class";

import {ReactComponent as Left} from '../assets/img/svg/left.svg';
import {ReactComponent as Right} from '../assets/img/svg/right.svg';
import {withRouter} from "react-router-dom";


let timer = null;
let subTimer = null;

class Slider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            headline: '',
            subheadline: '',
            slides: [],
            imagePosition: '',
            current: 0
        };
    }

    go(to, id) {
        clearTimeout(timer);
        clearTimeout(subTimer);

        let slidesContainer = document.querySelector('#' + id + ' .deviceContainerInner');
        let allSlides = document.querySelectorAll('#' + id + ' .deviceContainerInner .imageBox');
        let currentSlide = document.querySelector('#' + id + ' .deviceContainerInner .imageBox.current');

        let slidesTextContainer = document.querySelector('#' + id + ' .textBox');
        let currentTextSlide = document.querySelector('#' + id + ' .textBox .text.current');

        let index, nextCurrentSlide, nextCurrentTextSlide;

        if (to === 'next') {
            if (!!currentSlide.nextSibling) {
                nextCurrentSlide = currentSlide.nextSibling;
                nextCurrentTextSlide = currentTextSlide.nextSibling;
            } else {
                nextCurrentSlide = slidesContainer.firstChild;
                nextCurrentTextSlide = slidesTextContainer.firstChild;
            }
        } else {
            if (!!currentSlide.previousSibling) {
                nextCurrentSlide = currentSlide.previousSibling;
                nextCurrentTextSlide = currentTextSlide.previousSibling;
            } else {
                nextCurrentSlide = slidesContainer.lastChild;
                nextCurrentTextSlide = slidesTextContainer.lastChild;
            }
            console.log(nextCurrentTextSlide);
        }

        index = Array.prototype.indexOf.call(allSlides, nextCurrentSlide);

        if (nextCurrentSlide !== currentSlide) {
            this.anim(nextCurrentSlide, nextCurrentTextSlide, to === 'next', index, id);
        }
    }


    goTo(to, id) {
        clearTimeout(timer);
        clearTimeout(subTimer);

        let allSlides = document.querySelectorAll('#' + id + ' .deviceContainerInner .imageBox');
        let currentSlide = document.querySelector('#' + id + ' .deviceContainerInner .imageBox.current');

        let allTextSlides = document.querySelectorAll('#' + id + ' .textBox .text');
        // let currentTextSlide = document.querySelector('#' + id + ' .textBox .text.current');

        let nextCurrentSlide = allSlides[to];
        let nextCurrentTextSlide = allTextSlides[to];

        if (nextCurrentSlide !== currentSlide) {
            this.anim(nextCurrentSlide, nextCurrentTextSlide, to > this.state.current, to, id);
        }

    }


    anim(nextCurrentSlide, nextCurrentTextSlide, to, key, id) {
        let slidesContainer = document.querySelector('#' + id + ' .deviceContainerInner');
        let allSlidesContainer = document.querySelector('#' + id + ' .deviceContainerInner .imageBox');
        let currentSlide = document.querySelector('#' + id + ' .deviceContainerInner .imageBox.current');

        let currentTextSlide = document.querySelector('#' + id + ' .textBox .text.current');
        let next;

        if (!!document.querySelector('#' + id + ' .deviceContainerInner .imageBox.prev')) {
            document.querySelector('#' + id + ' .deviceContainerInner .imageBox.prev').classList.remove('prev');
        }
        if (!!document.querySelector('#' + id + ' .deviceContainerInner .imageBox.next')) {
            document.querySelector('#' + id + ' .deviceContainerInner .imageBox.next').classList.remove('next');
        }

        if (!!document.querySelector('#' + id + ' .textBox .text.prev')) {
            document.querySelector('#' + id + ' .textBox .text.prev').classList.remove('prev');
        }
        if (!!document.querySelector('#' + id + ' .textBox .text.next')) {
            document.querySelector('#' + id + ' .textBox .text.next').classList.remove('next');
        }


        if (!!nextCurrentSlide && !!nextCurrentSlide.nextSibling) {
            next = nextCurrentSlide.nextSibling;
        } else {
            next = slidesContainer.firstChild;
        }
        // if (!!nextCurrentSlide.previousSibling) {
        //     prev = nextCurrentSlide.previousSibling;
        // } else {
        //     prev = slidesContainer.lastChild;
        // }

        if (to) {
            /* remove anim and scroll box to left = 0 */
            slidesContainer.style.transition = 'none';
            slidesContainer.style.left = 0;

            /* put current slide on position 0 so it is the first visible slide */
            currentSlide.classList.add('prev');

            currentTextSlide.classList.add('prev');

            /* put next slide on position 1 so it is the next visible slide */
            setTimeout(() => {
                nextCurrentSlide.classList.add('current');

                setTimeout(() => {
                    slidesContainer.style.transition = '';
                    slidesContainer.style.left = '-100%';
                },1);

                setTimeout(() => {
                    nextCurrentTextSlide.classList.add('current');
                },400);
            }, 400);

        } else {
            nextCurrentSlide.classList.add('current');

            /* remove anim and scroll box to left = 0 */
            slidesContainer.style.transition = 'none';
            slidesContainer.style.left = '-100%';

            /* put current slide on position 0 so it is the first visible slide */
            currentSlide.classList.add('next');
            currentTextSlide.classList.add('next');

            setTimeout(() => {

                setTimeout(() => {
                    slidesContainer.style.transition = '';
                    slidesContainer.style.left = '0';
                },1);

                setTimeout(() => {

                    nextCurrentTextSlide.classList.add('current');
                },400);
            }, 400);
        }

        currentSlide.classList.remove('current');
        currentTextSlide.classList.remove('current');

        timer = setTimeout(() => {
            if (allSlidesContainer.length > 2) {
                next.classList.add('next');
            }

            subTimer = setTimeout(() => {
                slidesContainer.style.transition = '';

                this.setState({
                    current: key
                });
            },400);
        },400);
    }


    render() {
        let left = '-100%';

        if (this.state.slides.length <= 1) {
            left = 0;
        }

        let _device = this.props.data.filter(h => h['type'] === 'Device')[0];
        let device = (!!_device && !!_device['content']) ? _device['content'] : null;
        let deviceBackground = (!!_device && !!_device['image']) ? _device['image'] : null;

        let background = this.props.data.filter(h => h['type'] === 'BackgroundImage')[0];
        background = (!!background && !!background['image']) ? background['image'] : null;

        let download = this.props.data.filter(h => h['type'] === 'Download');

        let _imagePosition = this.props.data.filter(h => h['type'] === 'Imageposition')[0]['content'];

        let imagePosition = 'left';

        if (!!_imagePosition) {
            imagePosition = (_imagePosition === 'left') ? ' left' : ' right';
        }

        return (
            <div className={'sliderBoxElm'} id={this.props.id}>
                <div className="horizontalShadow" />

                <div className={'headlines'}>
                    <h2>
                        {this.state.headline}
                    </h2>

                    <h3>
                        {this.state.subheadline}
                    </h3>
                </div>

                <div className={'sliderBackgroundBox ' + device + ' ' + imagePosition + ' ' + (!background ? 'withBG' : '')}>
                    {!!background &&
                        <div className={'imgBox'}>
                            <div className={'imgInner'}>
                                <LazyImage dkey={'slider_' + this.props.id} className={'img'} alt={'slider_img_' + this.props.id} src={'image.php?file=' + background + '&height=800&width=1920'} parallax={true} />
                            </div>
                        </div>
                    }

                    <div className={'sliderOuter'}>
                        {(this.state.slides.length > 1) &&
                        <>
                            <div className={'prevSlide'} onClick={() => this.go('prev', this.props.id)}>
                                <Left />
                            </div>
                            <div className={'nextSlide'} onClick={() => this.go('next', this.props.id)}>
                                <Right />
                            </div>
                        </>
                        }


                        <div className={'slideBox'}>

                            <div className={'slides'}>

                                <div className={'deviceBox'} style={{backgroundImage: (deviceBackground ? 'url(assets/' + deviceBackground + ')' : '')}}>
                                    <div className={'deviceContainer'}>
                                        <div className={'deviceContainerInner'} style={{left:left}}>
                                            {
                                                (this.state.slides.length > 0) && this.state.slides.map((slide, key) => {

                                                    let className = '';

                                                    if (key === 0) {
                                                        className += ' current';
                                                    } else if ( key === this.state.slides.length-1) {
                                                        className += ' prev';
                                                    } else if (key !== 0) {
                                                        className += ' next';
                                                    }

                                                    return (
                                                        <div key={'image_slide_' + key} className={'imageBox' + className}>
                                                            <ImageSlider images={slide['image'].split(',')} id={this.props.id} tab={slide['type']} forceLoad={this.state.current===key} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={'contentBox'}>
                                    <div className={'textBox'}>
                                        {
                                            (this.state.slides.length > 0) && this.state.slides.map((slide, key) => {

                                                let className = '';

                                                if (key === 0) {
                                                    className += ' current';
                                                } else if ( key === this.state.slides.length-1) {
                                                    className += ' prev';
                                                } else if (key !== 0) {
                                                    className += ' next';
                                                }

                                                return (
                                                    <p key={'text_slide_' + key} className={'text' + className} dangerouslySetInnerHTML={{__html: (!!slide['content'] ? marked(slide['content']) : '')}} />
                                                )
                                            })
                                        }
                                    </div>

                                    <div className={'bottomBox'}>
                                        {(this.state.slides.length > 1) &&
                                        <div className={'bubbleBox'}>
                                            {
                                                (this.state.slides.length > 1) &&
                                                this.state.slides.map((slide, key) => {
                                                        return <div key={'bubble_' + key} className={'bubble' + (this.state.current === key ? ' current' : '')} onClick={() => this.goTo(key, this.props.id)} />;
                                                    }
                                                )
                                            }
                                        </div>
                                        }

                                        <div className={'download'}>

                                            {download.map((d, k) => {
                                                return (
                                                    <a key={'download_' + k} href={d['content']} target={'_blank'}>
                                                        <img src={'assets/' + d['image']} alt={'Download'} />
                                                    </a>
                                                )
                                            })}

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    componentDidMount() {
        this.setState({
            slides: this.props.data.filter(d => d['type'] !== 'Headline' && d['type'] !== 'Subheadline' && d['type'] !== 'BackgroundImage' && d['type'] !== 'Imageposition' && d['type'] !== 'Device' && d['type'] !== 'Download'),
            headline: this.props.data.filter(d => d['type'] === 'Headline')[0]['content'],
            subheadline: this.props.data.filter(d => d['type'] === 'Subheadline')[0]['content'],
            imagePosition: this.props.data.filter(d => d['type'] === 'Imageposition')[0]
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                slides: this.props.data.filter(d => d['type'] !== 'Headline' && d['type'] !== 'Subheadline' && d['type'] !== 'BackgroundImage' && d['type'] !== 'Imageposition' && d['type'] !== 'Device' && d['type'] !== 'Download'),
                headline: this.props.data.filter(d => d['type'] === 'Headline')[0]['content'],
                subheadline: this.props.data.filter(d => d['type'] === 'Subheadline')[0]['content'],
                imagePosition: this.props.data.filter(d => d['type'] === 'Imageposition')[0]
            });
        }
    }
}

export default withRouter(Slider);
